import React from "react";

const planContents = [
  {
    header: "Impala Package",
    price: 1350,
    features: [
      "12 - 20 People",
      "Accommodation Included",
      "Meals Included",
      "All Standard Activities Included"
    ],
    buttonLabel: "Sign up for free",
    outline: true
  },
  {
    header: "Kudu Package",
    price: 1250,
    features: [
      "",
      "21 - 35 People",
      "Accommodation Included",
      "Meals Included",
      "All Standard Activities Included"
    ],
    buttonLabel: "Get started",
    outline: false
  },
  {
    header: "Buffalo Package",
    price: 1150,
    features: [
      "36 - 50 People",
      "Accommodation Included",
      "Meals Included",
      "All Standard Activities Included"
    ],
    buttonLabel: "Contact us",
    outline: false
  }
];

const Plan = props => {
  return (
    <div className="card mb-4 shadow-sm">
      <div className="card-header">
        <h4 className="my-0 font-weight-normal">
          {props.header}
        </h4>
      </div>
      <div className="card-body">
        <h1 className="card-title pricing-card-title">
          {`R${props.price}`}
          <small className="text-muted">
            / pp
          </small>
        </h1>
        <ul className="list-unstyled mt-3 mb-4">
          {props.features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
        {/* <button
          className={`btn btn-lg btn-block ${
            props.outline
              ? "btn-outline-primary"
              : "btn-primary"
          }`}
          type="button"
        >
          {props.buttonLabel}
        </button> */}
      </div>
    </div>
  );
};

const Plans = () => {
  const plans = planContents.map((obj, i) => {
    return (
      <Plan
        key={obj.header}
        header={obj.header}
        price={obj.price}
        features={obj.features}
        buttonLabel={obj.buttonLabel}
        outline={obj.outline}
      />
    );
  });

  return (
    <div className="card-deck mb-3 text-center">
      {plans}
    </div>
  );
};

export default Plans;
